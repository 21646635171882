import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import Layout from '../layouts/index';

const Home = (props) => {
  const products = props?.data.allFile.edges;
  const instagram = props?.data.allInstaNode.edges;

  const toDateString = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const prefix = x => (x < 10 ? `0${x}` : x);
    return `${prefix(date.getDate())}.${prefix(date.getMonth() + 1)}.${date.getFullYear()}`;
  };

  return (
    <Layout bodyClass="page-home">
      <SEO title="Startseite" />
      <Helmet>
        <meta
          name="description"
          content="Ihr Schreibwarenhändler im Herzen von Gengenbach."
        />
      </Helmet>

      <div className="intro intro-small pb-4">
        <div className="container">
          <h1>Ahne-Schreibwaren</h1>
          <p>
            Ihr Schreibwarenhändler im Herzen von Gengenbach.
          </p>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-9">
        <h2 className="title-3 text-center mb-4">
          Folge uns auf 
          <a href="https://www.instagram.com/schreibwaren_ahne/" target="_blank" rel="noopener noreferrer">Instagram</a>
        </h2>
        <div className="row justify-content-center mb-2">
          {instagram.map(({ node }) => (
            <div key={node.id} className="card card-lifted m-1" style={{ width: 'min-content' }}>
              <div className="card-content">
                <a key={node.id} href={`https://www.instagram.com/p/${node.id}/`} target="_blank" rel="noopener noreferrer">
                  <Img className="card-image" fixed={node.localFile.childImageSharp.fixed} />
                </a>
                <p className="mt-2">{node.caption}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="col-12 text-center">
          <Link className="button button-primary mt-2" to="/galerie">
            Zur Galerie
          </Link>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-9">
        <div className="row justify-content-center">
          <div className="col-12">
          <h2 className="title-3 text-center mb-4">Unser neues Highlight</h2>
          <h2 className="title-3 text-center mb-4">Schulrucksäcke und Zubehör von Deuter </h2>
          </div>
          {products.map(({ node }) => (
            <div key={node.id} className="card-lifted m-1">
              <Img
                className="card-image"
                fixed={node.childImageSharp.fixed}
              />
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/sortiment">
              Unser Sortiment
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

/*
  <a key={node.id} href={`https://www.instagram.com/p/${node.id}/`} target="_blank" rel="noopener noreferrer">
    <div className="overlay-container">
      <Img className="card-image" fixed={node.localFile.childImageSharp.fixed} />
      <div className="overlay">
        <span>{ toDateString(node.timestamp) }</span>
        <div className="ig-likes">{ node.likes }</div>
      </div>
    </div>
  </a>
*/

export const query = graphql`
  query {

    allInstaNode(
      limit: 3,
      sort: {
        fields: timestamp,
        order: DESC
      }
    ) {
      edges {
        node {
          id
          likes
          caption
          localFile {
            childImageSharp {
              fixed(quality: 70, width: 300, height: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          timestamp
        }
      }
    }

    allFile(
      limit: 3,
      filter: {
        sourceInstanceName: {eq: "promoted-products"}, 
        extension: {regex: "/jpeg|jpg|png|gif/"}
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fixed(quality: 70, width: 300) {
              ...GatsbyImageSharpFixed
              src
            }
          }
        }
      }
    }

  }
`;

export default Home;
